export default {
  title: 'Tom Wojcik personal blog',

  author: 'Tom Wojcik',
  description:
    "Tom Wojcik personal blog",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
