import React, {useEffect} from 'react'
import { Link, useCurrentRoute, useView } from 'react-navi'
import { MDXProvider } from '@mdx-js/react'
import siteMetadata from '../siteMetadata'
import ArticleMeta from './ArticleMeta'
import Bio from './Bio'
import styles from './BlogPostLayout.module.css'
import ClipboardJS from 'clipboard'

interface BlogPostLayoutProps {
  blogRoot: string
}


function updateCodeBlocks(event) {
  event.stopPropagation()

  const copyIcon =
    '<svg width="12" height="12" viewBox="340 364 14 15" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M342 375.974h4v.998h-4v-.998zm5-5.987h-5v.998h5v-.998zm2 2.994v-1.995l-3 2.993 3 2.994v-1.996h5v-1.995h-5zm-4.5-.997H342v.998h2.5v-.997zm-2.5 2.993h2.5v-.998H342v.998zm9 .998h1v1.996c-.016.28-.11.514-.297.702-.187.187-.422.28-.703.296h-10c-.547 0-1-.452-1-.998v-10.976c0-.546.453-.998 1-.998h3c0-1.107.89-1.996 2-1.996 1.11 0 2 .89 2 1.996h3c.547 0 1 .452 1 .998v4.99h-1v-2.995h-10v8.98h10v-1.996zm-9-7.983h8c0-.544-.453-.996-1-.996h-1c-.547 0-1-.453-1-.998 0-.546-.453-.998-1-.998-.547 0-1 .452-1 .998 0 .545-.453.998-1 .998h-1c-.547 0-1 .452-1 .997z" fill-rule="evenodd"/></svg>';

  function button(label, ariaLabel, icon, className) {
      const btn = document.createElement('button');
      btn.classList.add('btnIcon', className);
      btn.setAttribute('type', 'button');
      btn.setAttribute('aria-label', ariaLabel);
      btn.innerHTML =
        '<div class="btnIcon__body">' +
        icon +
        '<strong class="btnIcon__label">' +
        label +
        '</strong>' +
        '</div>';
      return btn;
  }

  function selectCodeBlocks() {
      const nodes = document.getElementsByTagName("code");
      const nodesArray = Array.prototype.slice.call(nodes)
      return nodesArray.filter(node => node.dataset.hasOwnProperty('language'))
  }
    function addCopyButtons() {
        selectCodeBlocks().forEach((codeBlock) => {
          const btn = button('Copy', 'Copy code to clipboard', copyIcon, 'btnClipboard')
          const parentNode = codeBlock.parentNode

          const hasButton = parentNode.getElementsByTagName("button")[0] !== undefined
          if (!hasButton) {
            codeBlock.parentNode.appendChild(btn.cloneNode(true))
          }
      })
    }

    addCopyButtons()

    const clipboard = new ClipboardJS('.btnClipboard', {
      target: function(trigger) {
        return trigger.parentNode.querySelector('code');
      },
    });

    clipboard.on('success', function(event) {
      event.clearSelection();

      const textEl = event.trigger.querySelector('.btnIcon__label');
      textEl.textContent = 'Copied';
      setTimeout(function() {
        textEl.textContent = 'Copy';
      }, 2000);
    });
}


function BlogPostLayout({ blogRoot }: BlogPostLayoutProps) {
  let { title, data, url } = useCurrentRoute()
  let { connect, content, head } = useView()!
  let { MDXComponent, readingTime } = content

  const event = new Event('copyButtonEvent');

  useEffect(() => {
    if (window) {
      window.addEventListener('copyButtonEvent', updateCodeBlocks)
    }
  }, [])

  useEffect(() => {
    if (window) {
      window.dispatchEvent(event)
    }
  }, [url, event])

  // The content for posts is an MDX component, so we'll need
  // to use <MDXProvider> to ensure that links are rendered
  // with <Link>, and thus use pushState.

  return connect(
    <>
      {head}
      <article className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.title}>
            <Link href={url.pathname}>{title}</Link>
          </h1>
          <ArticleMeta
            blogRoot={blogRoot}
            data={data}
            readingTime={readingTime}
          />
        </header>
        <MDXProvider
          components={{
            a: Link,
            wrapper: ({ children }) => (
              <div className={styles.content}>{children}</div>
            ),
          }}>
          <MDXComponent />
        </MDXProvider>
        <footer className={styles.footer}>
          <h3 className={styles.title}>
            <Link href={blogRoot}>{siteMetadata.title}</Link>
          </h3>
          <Bio className={styles.bio} />
          <section className={styles.links}>
            {data.previousDetails && (
              <Link
                className={styles.previous}
                href={data.previousDetails.href}>
                ← {data.previousDetails.title}
              </Link>
            )}
            {data.nextDetails && (
              <Link className={styles.next} href={data.nextDetails.href}>
                {data.nextDetails.title} →
              </Link>
            )}
          </section>
        </footer>
      </article>
    </>
  )
}

export default BlogPostLayout
