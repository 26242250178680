import React from 'react'
import styles from './Bio.module.css'

interface BioProps {
  className?: string
}

function Bio(props: BioProps) {
  return (
    <div
      className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={`https://pbs.twimg.com/profile_images/474909639776014337/YAZUSGzm_400x400.jpeg`} alt="Me" />
      <p>
        Personal blog, by{' '}
        <a href="https://twitter.com/TomWojcik1/">Tom Wójcik</a>.
        <br />
      </p>

    </div>
  )
}

export default Bio
